@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Comfortaa', cursive;
  color: #2B2D42;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #2b2d42;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2b2d42;
}